// vueform.config.(js|ts)

import en from '@vueform/vueform/locales/en'
import vueform from '@vueform/vueform/dist/vueform.vue2'
import { defineConfig } from '@vueform/vueform'

// You might place these anywhere else in your project

en.validation.email = 'Adres e-mail musi być prawidłowy'
en.validation.required = "Pole :attribute jest wymagane."
// en.validation.email = 'Adres e-mail musi być prawidłowy'
// en.validation.email = 'Adres e-mail musi być prawidłowy'
// en.validation.email = 'Adres e-mail musi być prawidłowy'
// en.validation.email = 'Adres e-mail musi być prawidłowy'
// en.validation.email = 'Adres e-mail musi być prawidłowy'
// en.validation.email = 'Adres e-mail musi być prawidłowy'
// en.validation.email = 'Adres e-mail musi być prawidłowy'

export default defineConfig({
  theme: vueform,
  locales: { en },
  locale: 'en',
})